import React from "react"
// import { Helmet } from "react-helmet"
import { Row, Col, Container } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import indexImage from "../images/monitoring-aerobarrier.jpg"
import { Script } from "gatsby"

// import serviceArea from "../images/service-area.jpg"

const aboutTheAuthor = props => (
  <Layout>
        <Seo title="Contact - Thank you"/>
        <Script id="second-unique-id" type="text/javascript">
          {`
            gtag('event', 'conversion', {'send_to': 'AW-11022073567/u0y8CJOs0IEYEN_93Ycp'});
          `}
        </Script>
        {/* <Helmet>
        <script>
          {`
            "gtag('event', 'conversion', {'send_to': 'AW-11022073567/u0y8CJOs0IEYEN_93Ycp'});"
          `}
          gtag('event', 'conversion', {'send_to': 'AW-11022073567/u0y8CJOs0IEYEN_93Ycp'}); 
        </script>
        </Helmet> */}
        <section className="page-section">

        <div className="masthead fixed" style={{ backgroundImage: `url(${indexImage})` }}>
          <Container className="container h-100">
            <Row className="row h-100 align-items-center">
              <Col md="12" className="text-center">
                <h1>Thank you</h1>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="mt-5">

          <p className="text-center">Thank you! We will be in touch shortly.</p>

        </Container>

          
        </section>
        
      </Layout>
);

export default aboutTheAuthor;

